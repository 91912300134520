import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import personimage from "../../images/person/p3.png";
import arrow from "../../images/svg/arrow.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUnlockAlt,
  faCogs,
  faQuestion,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";

import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";

function Profilepage() {
  const [profileusername, setprofileusername] = useState();
  const [profilephonenumber, setprofilephonenumber] = useState();
  const [profileimage, setprofileimage] = useState();
  const { auth } = useAuth();

  const profileaccount = async () => {
    var response = await ApiService.getprofile(auth);
    const { status, message, data } = response.data;
    if(status)
    {
      setprofileusername(data.memberid);
      setprofilephonenumber(data.email);
    }
  };

  useEffect(() => {
    // setprofileusername("");
    // setprofilephonenumber("");
    profileaccount();
    setprofileimage(personimage);
  }, []);

  return (
    <>
      <div className="section section-b-space-">
        <div className="custom-container">
          <div className="profile-section">
            <div className="profile-banner">
              <div className="profile-image">
                <img
                  className="img-fluid profile-pic"
                  src={profileimage}
                  alt="p3"
                />
              </div>
            </div>
            <h2>{profileusername}</h2>
            <h5>{profilephonenumber}</h5>
          </div>
        </div>
      </div>
      <div
        className="section-b-space"
        style={{ borderRadius: "10px 10px 0px 0px" }}
      >
        <div className="custom-container">
          <ul className="profile-list">
            <li>
              <Link to='/account' className="profile-box">
                <div className="profile-img">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faUser}
                  ></FontAwesomeIcon>
                </div>
                <div className="profile-details">
                  <h4>My Account</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>

            {/* <li>
              <Link className="profile-box">
                <div className="profile-img">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faUnlockAlt}
                  ></FontAwesomeIcon>
                </div>
                <div className="profile-details">
                  <h4>Change Password</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li> */}
            
            <li>
              <Link className="profile-box" to="/setting">
                <div className="profile-img">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faCogs}
                  ></FontAwesomeIcon>
                </div>
                <div className="profile-details">
                  <h4>Settings</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>

            <li>
              <Link className="profile-box" to="/help">
                <div className="profile-img">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faQuestion}
                  ></FontAwesomeIcon>
                </div>
                <div className="profile-details">
                  <h4>Help Center</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>
            <li>
              <Link className="profile-box" to="/logout">
                <div className="profile-img">
                  <FontAwesomeIcon
                    className="icon"
                    icon={faSignOut}
                  ></FontAwesomeIcon>
                </div>
                <div className="profile-details">
                  <h4>Log Out</h4>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <section className="panel-space"></section>
    </>
  );
}

export default Profilepage;
