
import React from 'react'
import Tether from '../images/tether.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxesStacked, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import{Link} from "react-router-dom"

const Withdrawhistorytab = ({activetab, setactivetab,dated,setdated}) => {

    const Onclicktab=(value)=>{
        setactivetab(value);
    }
    
    return (
        <div>
            <ul className="nav-tab">
                <li><Link onClick={()=>{Onclicktab(1)}} className={activetab==1?'active':''}><FontAwesomeIcon icon={faBoxesStacked} className='icon' /> ALL</Link></li>
                <li><Link onClick={()=>{Onclicktab(2)}} className={activetab==2?'active':''}> <FontAwesomeIcon icon={faCreditCard} className='icon' /> BANK CARD</Link></li>
                <li><Link onClick={()=>{Onclicktab(3)}} className={activetab==3?'active':''}><img src={Tether} alt="" width="25px" /> USDT</Link></li>
            </ul>
            <div className="row g-2">
                <div className="col-6">
                    <select className="form-select" aria-label="Default select example">
                        <option selected>All</option>
                        <option value="1">Processing</option>
                        <option value="2">Completed</option>
                        <option value="3">Rejected</option>
                    </select>
                </div>
                <div className="col-6">
                    <input type="date" className="form-control" value={dated} onChange={(e)=>setdated(e.target.value)} />
                </div>
            </div>
        </div>
    )
}

export default Withdrawhistorytab
