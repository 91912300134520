import React from "react";
import Modal from "react-bootstrap/Modal";
import Won from "../../../images/won-label.png";
import "./winmodel.css";


const WinModal = ({ show,setShow,number,color,shape,wonamount ,wongamename}) => {
  //const [show, setShow] = useState(shows);

  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  return (
    <>
      <Modal show={show} onHide={handleClose} className="winmod">
        <Modal.Header closeButton>
          <img src={Won} alt="" className="headlabel" />
        </Modal.Header>
        <Modal.Body>
          <h2>Congratulations!</h2>
          <div className="lottery">
            <h6>Game Result</h6>
            <ul>
              <li>{color}</li>
              <li>{number}</li>
              <li>{shape}</li>
            </ul>
          </div>
          <div className="bonus">
            <span>Reward</span>
            <h3>₹{wonamount}</h3>
          </div>
          <p>Game: {wongamename}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WinModal;
