import React from 'react'
import tether from '../../images/tether.png'
import upi from '../../images/upi.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
const Transfer = ({titles}) => {
    titles('Transfer');
    return (
        <div>
          <section>
                <div className="custom-container">
                    <div className="statistics-banner">
                        <div className="d-flex justify-content-center gap-3">
                            <div className="statistics-content">
                                <h6>Total Balance</h6>
                                <h5 className="text-white fw-semibold">$0</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='add-money-2'>
                <div className="custom-container">
                    <div className="title">
                        <h6 className="white-text"><FontAwesomeIcon icon={faWallet} className='iconsize' /> Select Wallet</h6>
                    </div>
                    <div className="row">
                        <div className="col-6 text-center">
                            <a href="" className='pmt-box active'>
                                <h6>Wallet 1</h6>
                            </a>
                        </div>
                        <div className="col-6 text-center">
                            <a href="" className='pmt-box'>
                                <h6>Wallet 2</h6>
                            </a>
                        </div>
                        <div className="col-4"><div className="amt">$10</div></div>
                        <div className="col-4"><div className="amt">$20</div></div>
                        <div className="col-4"><div className="amt">$50</div></div>
                        <div className="col-4"><div className="amt">$100</div></div>
                        <div className="col-4"><div className="amt">$200</div></div>
                        <div className="col-4"><div className="amt">$500</div></div>
                    </div>
                    <div className='deposit-details transfer-details'>
                        <div className="title">
                            <h6 className="white-text mb-0"><FontAwesomeIcon icon={faWallet}/> Wallet 1</h6>
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">$</span>
                            <input type="text" className="form-control" placeholder="Please Enter The Amount" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        <button className="pmt-btn mb-2">Transfer Fund</button>
                        {/* <ul className='notes'>
                            <li>* If you transfer the wrong amount as instructed, the funds will be lost, and our company will not be held responsible!
                            </li>
                            <li>* You must deposit the exact amount as instructed in
                                the order. The system will automatically update the bonus
                                funds.
                            </li>
                        </ul> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Transfer
