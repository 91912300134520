import React from "react";

function CurrentBetting({ items }) {
  //console.log(items);
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Draw</th>
            <th>Bet</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {items.length > 0 ? (
            items.map((item, index) => (
              <tr key={index}>
                <td>{item.gamename}</td>
                <td>{item.gametype}</td>
                <td>₹{item.amount}</td>
                <td>{item.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <p>No Record Found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default CurrentBetting;
