import React, { useDebugValue, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import tether from "../../images/tether.png";
import upi from "../../images/upi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCardAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare";
import { useForm } from "react-hook-form";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";
import { ToastContainer, toast } from "react-toastify";

function BankDetails({ titles }) {
  titles("Bank Details");
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      payeename: null,
      bankname: null,
      bankbranch: null,
      accountnumber: null,
      accounttype: "Savings",
      ifsccode: null,
      pancard: null,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    profileaccount();
   
   
    return () => {
     
    };
  }, []);

  const profileaccount = async () => {
    var response = await ApiService.getaccount({}, auth);
    const { status, message, data } = response.data;

    if (status) {
      setValue("payeename", data.payeename);
      setValue("bankname", data.bankname);
      setValue("accountnumber", data.accountnumber);
      setValue("ifsccode", data.ifsccode);
      setValue("pancard", data.pancard);
      setValue("bankbranch", data.bankbranch);
      setValue("accounttype", data.accounttype);
    }
  };

  const Updateprofilebanking = async (paydata) => {
    var response = await ApiService.updatebankingdetails(
      {
        payeename: paydata.payeename,
        bankname: paydata.bankname,
        bankbranch: paydata.bankbranch,
        accountnumber: paydata.accountnumber,
        accounttype: paydata.accounttype,
        ifsccode: paydata.ifsccode,
        pancard: paydata.pancard,
      },
      auth
    );
    //console.log(response);
    const { status, message, errors, data } = response.data;

    if (status) {
      toast(message, { type: "success", isLoading: false });
      
    } else {
      if (errors.length > 0) {
        errors.forEach((element) => {
          toast(element, { type: "error", isLoading: false });
        });
      }
      else{
        toast(message, { type: "error", isLoading: false });
      }
    }
  };
  const Submitwithdrawal = async (data) => {
    await Updateprofilebanking(data);
    //Api Code
    // navigate("/home");
  };

  return (
    <>
     <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition: Slide
      />
      <section className="">
        <div className="custom-container">
          <form onSubmit={handleSubmit(Submitwithdrawal)}>
            <div className="title">
              <h6 className="white-text">
                <FontAwesomeIcon icon={faCreditCardAlt} className="iconsize" />{" "}
                Update Your Bank Details
              </h6>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("payeename", { required: true })}
                    className={`form-control ${
                      errors.payeename ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Payee Name"
                    autoComplete="off"
                  />
                  {errors.payeename && (
                    <div className="invalid-feedback">
                      Payee Name is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("bankname", { required: true })}
                    className={`form-control ${
                      errors.bankname ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Bank Name"
                    autoComplete="off"
                  />
                  {errors.bankname && (
                    <div className="invalid-feedback">
                      Bank Name is required
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("bankbranch", { required: true })}
                    className={`form-control ${
                      errors.bankbranch ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Bank Branch"
                    autoComplete="off"
                  />
                  {errors.bankbranch && (
                    <div className="invalid-feedback">
                      Bank Branch is required
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("accountnumber", { required: true })}
                    className={`form-control ${
                      errors.accountnumber ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Account Number"
                    autoComplete="off"
                  />
                  {errors.accountnumber && (
                    <div className="invalid-feedback">
                      Account Number is required
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="input-group mb-3">
                  <select
                    id="accountType"
                    className={`form-control form-select ${
                      errors.accounttype ? "is-invalid" : ""
                    }`}
                    name="accountType"
                    {...register("accounttype", { required: true })}
                  >
                    <option value="Savings">Savings</option>
                    <option value="Current">Current</option>
                  </select>
                  {errors.accounttype && (
                    <span>Please select an account type</span>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("ifsccode", { required: true })}
                    className={`form-control ${
                      errors.ifsccode ? "is-invalid" : ""
                    }`}
                    placeholder="Enter IFSC Code"
                    autoComplete="off"
                  />
                  {errors.ifsccode && (
                    <div className="invalid-feedback">
                      IFSC Code is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("pancard", { required: true })}
                    className={`form-control ${
                      errors.pancard ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Pan Card"
                    autoComplete="off"
                  />
                  {errors.pancard && (
                    <div className="invalid-feedback">Pan Card is required</div>
                  )}
                </div>
              </div>
              <div className="col-12 mt-3">
                <button type="submit" className="pmt-btn mb-2">
                  Update Bank Details
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default BankDetails;
