import React, { useState } from "react";

function Helppage({ titles }) {
  titles("Help");

  // State to track which accordion is open
  const [openAccordion, setOpenAccordion] = useState(null);

  // Function to toggle accordion
  const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? null : id);
  };

  const faqData = [
    {
      id: 1,
      question: "What is a PWA Template?",
      answer:
        "A PWA (Progressive Web App) template is a pre-designed starting point or framework that helps developers build Progressive Web Applications more efficiently...",
    },
    {
      id: 2,
      question: "What is Cryptocurrency?",
      answer:
        "Cryptocurrency, often referred to as crypto, is a digital or virtual form of currency that uses cryptography for security...",
    },
    {
      id: 3,
      question: "How to make a payment through Crypto?",
      answer: (
        <ul>
          <li>
            Choose a Cryptocurrency: Decide which cryptocurrency you want to use
            for the payment...
          </li>
          <li>
            Choose a Wallet: Select a digital wallet that supports the
            cryptocurrency you're using...
          </li>
          <li>
            Initiate the Transaction: Open your chosen wallet and find the
            option to send or transfer funds...
          </li>
          <li>
            Confirm Transaction Details: Some wallets may ask you to review the
            transaction details...
          </li>
          <li>
            Pay Transaction Fees: Cryptocurrency transactions often incur
            fees...
          </li>
        </ul>
      ),
    },
    {
      id: 4,
      question: "How to utilize coin in cryptocurrency?",
      answer: (
        <ul>
          <li>
            Choose a Wallet: Select a digital wallet that supports the specific
            coin you want to utilize...
          </li>
          <li>
            Obtain the Coin: You need to acquire the coin through a
            cryptocurrency exchange or a peer-to-peer transaction...
          </li>
          <li>
            Store the Coin: Once you have obtained the coin, transfer it to your
            chosen wallet...
          </li>
          <li>
            Secure your Wallet: It's crucial to keep your wallet secure to
            protect your coins...
          </li>
        </ul>
      ),
    },
    {
      id: 5,
      question: "How will the money be transferred to my wallet?",
      answer: (
        <ul>
          <li>
            Bank Transfer: You can transfer money from your bank account to your
            wallet...
          </li>
          <li>
            Credit/Debit Card: Many wallet services allow you to add funds using
            a credit or debit card...
          </li>
          <li>
            Mobile Payment Apps: Some wallets are integrated with mobile payment
            apps like Google Pay, PayPal...
          </li>
          <li>
            Cryptocurrency Transfer: You can receive funds by providing the
            sender with your wallet address or QR code...
          </li>
        </ul>
      ),
    },
    {
      id: 6,
      question: "How to Play ?",
      answer: (
        <ul>
          <li>Click on Home Page then Click on 1 min Game</li>
        </ul>
      ),
    },
  ];

  return (
    <section className="section-b-space">
      <div className="auth-form">
        <div className="custom-container">
          <div className="help-center">
            <h2 className="fw-semibold">What issues are you facing?</h2>
            <div
              className="accordion help-accordion"
              id="accordionFlushExample"
            >
            
              {faqData.map((faq) => (
                <div className="accordion-item" key={faq.id}>
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${
                        openAccordion !== faq.id ? "collapsed" : ""
                      }`}
                      type="button"
                      onClick={() => toggleAccordion(faq.id)}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    className={`accordion-collapse collapse ${
                      openAccordion === faq.id ? "show" : ""
                    }`}
                  >
                    <div className="accordion-body">{faq.answer}</div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Helppage;
