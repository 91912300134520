import React from "react";
import slide1 from "../../images/banner3.png";
import slide2 from "../../images/banner2.png";
import slide3 from "../../images/banner1.png";
import game1 from "../../images/game1.png";
import game2 from "../../images/game2.png";
import game3 from "../../images/game3.png";
import game4 from "../../images/game4.png";
import game5 from "../../images/game5.png";
import game6 from "../../images/game6.png";
import game7 from "../../images/game7.png";
import game8 from "../../images/game8.png";
import Lotcat from "../../images/lott-cat.png";
import ImageSlider from "../../component/imageslider/ImageSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faArrowRightArrowLeft,
  faBuildingColumns,
  faGamepad,
  faMoneyBillTransfer,
  faBookReader,
} from "@fortawesome/free-solid-svg-icons";
import WinningList from "../../component/WinningList";
import { Link } from "react-router-dom";
import EarningChart from "../../component/EarningChart";

const images = [slide1, slide2, slide3, slide1, slide2, slide3];

function Homepage() {
  return (
    <>
      <section className="pt-0">
        <ImageSlider images={images}></ImageSlider>
      </section>

      <section className="pt-4 games">
        <div className="custom-container">
         
                   <div className="row mt-4 g-2">
          <div className="col-4">
              <a href="#lot-cat-1">
                <img src={game1} alt="" className="game-img" loading="lazy" />
                <h6>Lottery</h6>
              </a>
            </div>
            <div className="col-4">
              <Link>
                <img src={game2} alt="" className="game-img" loading="lazy" />
                <h6>Orignal</h6>
              </Link>
            </div>
            <div className="col-4">
              <Link>
                <img src={game3} alt="" className="game-img" loading="lazy" />
                <h6>Slot</h6>
              </Link>
            </div>
          </div>
          <div className="game-row2">
            <div className="row g-1">
              <div className="col-4">
                <Link>
                  <img
                    src={game4}
                    alt=""
                    className="game-img2"
                    loading="lazy"
                  />
                  <h6>Sports</h6>
                </Link>
              </div>
              <div className="col-4">
                <Link>
                  <img
                    src={game5}
                    alt=""
                    className="game-img2"
                    loading="lazy"
                  />
                  <h6>Popular</h6>
                </Link>
              </div>
              <div className="col-4">
                <Link>
                  <img
                    src={game6}
                    alt=""
                    className="game-img2"
                    loading="lazy"
                  />
                  <h6>Casino</h6>
                </Link>
              </div>
            </div>
          </div>
          <div className="row game-row3 g-2">
            <div className="col-6">
              <Link>
                <img src={game7} alt="" className="game-img3" loading="lazy" />
                <h6>Rummy</h6>
              </Link>
            </div>
            <div className="col-6">
              <Link>
                <img src={game8} alt="" className="game-img3" loading="lazy" />
                <h6>Fishing</h6>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="lot-cat-1">
        <div className="custom-container">
          <div className="title">
            <h6 className="white-text">Lottery</h6>
          </div>
          <div className="row g-2">
            
            <div className="col-4">
            <Link to='/game?id=2'>
              <div className="lot-cat">
                <img src={Lotcat} alt="" />
                <div className="time">1 Min</div>
              </div>
              </Link>
            </div>
            <div className="col-4">
            <Link to='/game?id=3'>
              <div className="lot-cat">
                <img src={Lotcat} alt="" />
                <div className="time">3 Min</div>
              </div>
              </Link>
            </div>
            <div className="col-4">
            <Link to='/game?id=4'>
              <div className="lot-cat">
                <img src={Lotcat} alt="" />
                <div className="time">5 Min</div>
              </div>
              </Link>
            </div>
            <div className="col-4">
              <Link to='/game?id=4'>
              <div className="lot-cat">
                <img src={Lotcat} alt="" />
                <div className="time">10 Min</div>
              </div>
              </Link>
            </div>
           
          </div>
        </div>
      </section>

      {/* <WinningList></WinningList>
      <EarningChart></EarningChart> */}

      <section className="panel-space"></section>

      <div className="modal add-money-modal fade" id="add-money" x="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">Add Money</h2>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-label mb-2">From</label>
                <div className="d-flex gap-2">
                  <select id="inputcard" className="form-select">
                    <option>**** **** **** 1566 - Saving a/c</option>
                    <option>**** **** **** 1566 - Saving a/c</option>
                    <option>**** **** **** 1566 - Saving a/c</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label mb-2">Amount</label>
                <div className="form-input">
                  <input
                    type="number"
                    className="form-control"
                    id="inputamount"
                  />
                </div>
              </div>
              <a
                href="landing.html"
                className="btn theme-btn successfully w-100"
              >
                Deposit
              </a>
            </div>
            <button
              type="button"
              className="btn close-btn"
              data-bs-dismiss="modal"
            >
              <i className="icon" data-feather="x"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="modal pay-modal fade" id="pay">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">Apple Bill Detail</h2>
            </div>
            <div className="modal-body">
              <ul className="details-list">
                <li>
                  <h3 className="fw-normal dark-text">Amount</h3>
                  <h3 className="fw-semibold theme-color">$49.85</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Bill date</h3>
                  <h3 className="fw-normal light-text">10 May, 2023</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Due Date</h3>
                  <h3 className="fw-normal error-color">31 May, 2023</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Bill number</h3>
                  <h3 className="fw-normal light-text">BM452695523</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Mobile number</h3>
                  <h3 className="fw-normal light-text">+91 ***** **256</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Bill status</h3>
                  <h3 className="fw-semibold error-color">Unpaid</h3>
                </li>
              </ul>
              <a
                href="make-payment.html"
                className="btn theme-btn successfully w-100"
              >
                Pay
              </a>
            </div>
            <button
              type="button"
              className="btn close-btn"
              data-bs-dismiss="modal"
            >
              <i className="icon" data-feather="x"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="modal pay-modal fade" id="paid">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">Mobile Bill Detail</h2>
            </div>
            <div className="modal-body">
              <ul className="details-list">
                <li>
                  <h3 className="fw-normal dark-text">Amount</h3>
                  <h3 className="fw-semibold theme-color">$69.49</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Bill date</h3>
                  <h3 className="fw-normal light-text">10 May, 2023</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Due Date</h3>
                  <h3 className="fw-normal light-text">22 May, 2023</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Bill number</h3>
                  <h3 className="fw-normal light-text">BM452695523</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Mobile number</h3>
                  <h3 className="fw-normal light-text">+91 ***** **256</h3>
                </li>
                <li>
                  <h3 className="fw-normal dark-text">Bill status</h3>
                  <h3 className="fw-semibold theme-color">Paid</h3>
                </li>
              </ul>
              <a
                href="landing.html"
                className="btn theme-btn successfully w-100"
              >
                Paid - Thank you !
              </a>
            </div>
            <button
              type="button"
              className="btn close-btn"
              data-bs-dismiss="modal"
            >
              <i className="icon" data-feather="x"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
