import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import ApiService from "../Api/ApiService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import useAuth from "../context/useAuth";

function Forgotpage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  //const { login,isAuthenticated } = useAuth();

  const onSubmit = async (data) => {
    const tid = toast.loading("Please wait...", { isLoading: true });

    // await login(
    //   data,
    //   (successmessage) => {
    //     toast.update(tid, {
    //       render: successmessage,
    //       type: "success",
    //       hideProgressBar: false,
    //       isLoading: false,
    //       autoClose: 2000,
    //     });

    //     setTimeout(() => {
    //       navigate("/home");
    //     }, 3000);
    //   },
    //   (errormessage) => {
    //     toast.update(tid, {
    //       render: errormessage,
    //       type: "error",
    //       hideProgressBar: false,
    //       isLoading: false,
    //       autoClose: 2000,
    //     });
    //   }
    // );
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="auth-form">
        <div className="custom-container">
          <div className="text-center">
          <img src="/logo.png" alt="" className="logo" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="form-main">
            <div className="form-group">
              <label htmlFor="emailid" className="form-label">
                Email ID
              </label>
              <input
                type="text"
                id="emailid"
                {...register("emailid", { required: true })}
                className={`form-control ${errors.emailid ? "is-invalid" : ""}`}
                placeholder="Enter Email ID"
                autoComplete="off"
              />
              {errors.emailid && (
                <div className="invalid-feedback">Email ID is required</div>
              )}
            </div>

            {/* <div className="remember-option mt-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Remember Me
              </label>
            </div>
            <a href="#" className="forgot">
              Forgot Pin?
            </a>
          </div> */}

            <Button type="submit" className="cmn-btn w-100">
              Forgot Password
            </Button>

            <p className="signup">
              Have an account? <Link to="/login">Sign in</Link>
            </p>
          </form>
        </div>
      </div>

      <div className="space-sticky"></div>
      <div className="border-sections-home"></div>
    </>
  );
}

export default Forgotpage;
