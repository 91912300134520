import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../context/useAuth";


function Logoutpage() {
  const navigate = useNavigate();
  const{logout}=useAuth();

  useEffect(() => {
    logout();
    
    navigate("/login");
  }, []);

  return <div>Logoutpage</div>;
}

export default Logoutpage;
