import React from "react";
// import slide1 from "../../images/banner1.png"
// import slide2 from "../../images/banner2.png"
import reward1 from "../../images/reward1.png";
import reward2 from "../../images/reward2.png";
import reward3 from "../../images/reward3.png";
import reward4 from "../../images/reward4.png";
import reward5 from "../../images/reward5.png";
import reward6 from "../../images/reward6.png";
// import ImageSlider from '../../component/imageslider/ImageSlider.jsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";

// const images = [
//     slide1,
//     slide2,
//     slide1,
//     slide2,
// ];

function Activitypage() {
  const rewards = [reward1, reward2, reward3, reward4, reward5, reward6,reward1, reward2, reward3, reward4, reward5, reward6,reward1, reward2, reward3, reward4, reward5, reward6];

  return (
    <div>
     
      <section className="reward-section">
        <div className="custom-container">
          <div className="title">
            <h6 className="white-text">Reward Information</h6>
          </div>
          <div className="row">
            {rewards.map((item, index) => (
              <div className="col-6 mb-3" key={index}>
                <div className="position-relative">
                  <img src={item} alt="" className="reward-img" />

                  <div className="overlay">
                    <FontAwesomeIcon icon={faLock} className="icon" />
                  </div>
                </div>
              </div>
            ))}
            {/* <div className="col-6 mb-3">
              <div className="position-relative">
                <img src={reward1} alt="" className="reward-img" />

                <div className="overlay">
                  <FontAwesomeIcon icon={faLock} className="icon" />
                </div>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="position-relative">
                <img src={reward2} alt="" className="reward-img" />

                <div className="overlay">
                  <FontAwesomeIcon icon={faLock} className="icon" />
                </div>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="position-relative">
                <img src={reward3} alt="" className="reward-img" />

                <div className="overlay">
                  <FontAwesomeIcon icon={faLock} className="icon" />
                </div>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="position-relative">
                <img src={reward4} alt="" className="reward-img" />

                <div className="overlay">
                  <FontAwesomeIcon icon={faLock} className="icon" />
                </div>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="position-relative">
                <img src={reward5} alt="" className="reward-img" />

                <div className="overlay">
                  <FontAwesomeIcon icon={faLock} className="icon" />
                </div>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="position-relative">
                <img src={reward6} alt="" className="reward-img" />

                <div className="overlay">
                  <FontAwesomeIcon icon={faLock} className="icon" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="panel-space"></section>
    </div>
  );
}

export default Activitypage;
