import React from "react";

function Settingpage({titles}) {
  titles('Setting');
  return (
    <>
      <section className="section-b-space">
        <div className="custom-container">
          <ul className="notification-setting">
            <li className="setting-title">
              <div className="notification pt-0">
                <h3 className="fw-semibold dark-text">Notification</h3>
              </div>
            </li>

            <li>
              <div className="notification">
                <h5 className="fw-normal dark-text">Payment notification</h5>
                <div className="switch-btn">
                  <input type="checkbox" checked />
                </div>
              </div>
            </li>

            <li>
              <div className="notification">
                <h5 className="fw-normal dark-text">Notification sound</h5>
                <div className="switch-btn">
                  <input type="checkbox" />
                </div>
              </div>
            </li>

            <li>
              <div className="notification pb-0">
                <h5 className="fw-normal dark-text">Bill due date</h5>
                <div className="switch-btn">
                  <input type="checkbox" checked />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Settingpage;
