import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./game.css";
import {
  walleticon,
  chip1,
  chip2,
  chip3,
  chip4,
  chip5,
  chip6,
  chip7,
  chip8,
  chip9,
  chip10,
  timer,
} from "./Gameimage";
import ModalComponent from "./ModalComponent";

import WinModel from "./popup/WinModal";
import LossModel from "./popup/LossModel";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useSound from "use-sound";
import ApiService from "../../Api/ApiService";
import Gamealltable from "./Tablescreen/Gamealltable";
import { faL } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../context/useAuth";

const sleep = async (ms) => {
  return await new Promise((resolve) => setTimeout(resolve, ms));
};
const quanitytype = (number) => {
  switch (number) {
    case 1:
      return 1;
      break;
    case 2:
      return 5;
      break;
    case 3:
      return 10;
      break;
    case 4:
      return 20;
      break;
    case 5:
      return 50;
      break;
    case 6:
      return 100;
      break;
    default:
      return 0;
      break;
  }
};
const choosebetcolor = (type) => {
  switch (type) {
    case 0:
      return "Zero";
      break;
    case 1:
      return "One";
      break;
    case 2:
      return "Two";
      break;
    case 3:
      return "Three";
      break;
    case 4:
      return "Four";
      break;
    case 5:
      return "Five";
      break;
    case 6:
      return "Six";
      break;
    case 7:
      return "Seven";
      break;
    case 8:
      return "Eight";
      break;
    case 9:
      return "Nine";
      break;
    case 21:
      return "Green";
      break;
    case 22:
      return "Yellow";
      break;
    case 23:
      return "Red";
      break;
    case 24:
      return "Big";
      break;
    case 25:
      return "Small";
      break;
    default:
      return "Choose Option";
      break;
  }
};

function Gamecolorpage() {
  const { auth } = useAuth();
  const firsttimerenderRef = useRef(true);
  const currentgamedescription = useRef("Waiting Game ID");
  const [Gametype, setgametype] = useState(1);
  const [gametime, setgametime] = useState(0);
  const [isgameActive, setgameisActive] = useState(false);
  const [gameData, setGameData] = useState({
    balance: 0,
    gameid: 0,
    quantity: 1,
    amount: 1,
    bettype: 0,
    currentgameid: "Waiting Game ID",
    previousgameid: "",
    randomq: 1,
    winnumber: undefined,
    shape: undefined,
    color: undefined,
    wonamount: 0,
    wongamename: undefined,
    istabActive: 1,
  });
  const [history, setHistory] = useState({
    gamehistoryitem: [],
    mybetshistoryitem: [],
    currentbetshistoryitem: [],
  });

  const [openpage, setopenpage] = useState(1);
  const [totalopenpage, settotalopenpage] = useState(1);

  const [openbetspage, setopenbetspage] = useState(1);
  const [totalopenbetspage, settotalopenbetspage] = useState(1);

  const [uiState, setUiState] = useState({ showModal: false, showbox: 1 });
  const [play, { stop }] = useSound("audio/di1.mp3", { loop: false });
  const [wingame, setwingame] = useState(false);
  const [lossgame, setlossgame] = useState(false);

  const minutes = Math.floor(gametime / 60);
  const reSeconds = gametime % 60;
  // Extract individual digits

  const minuteStr = minutes.toString().padStart(2, "0");
  const secondStr = reSeconds.toString().padStart(2, "0");

  const m1 = minuteStr.charAt(0);
  const m2 = minuteStr.charAt(1);
  const s1 = secondStr.charAt(0);
  const s2 = secondStr.charAt(1);

  const Showpopupwindow = async () => {
    //Check Start Previous Game Winner//
    await sleep(2000);
    var responsegg = await ApiService.LastGameresult(Gametype, auth);
    const { status, message, data } = responsegg.data;
    //console.log({ data });
    if (status) {
      const {
        color,
        gamename,
        gamestatus,
        isjoin,
        lossamount,
        number,
        shape,
        winamount,
      } = data;
      if (isjoin) {
        setGameData((prevGameData) => ({
          ...prevGameData,
          wonamount: winamount,
          wongamename: gamename,
        }));

        if (isjoin) {
          if (gamestatus) {
            setwingame(true);
          } else {
            setwingame(false);
          }

          if (!gamestatus) {
            setlossgame(true);
          } else {
            setlossgame(false);
          }
        }
      }
    }

    //Check End Previous Game Winner//
  };

  const StartTimer = async () => {
    await sleep(2000);

    await LoadTimerstart();
    await LoadBalance();
    //Load Balance

    await LoadGameList();
    await currentbetsload();
    await LoadBetting();
  };
  const LoadBalance = async () => {
    var response = await ApiService.GameBalanceApi(auth);
    if (response.data != null) {
      setGameData((prevData) => ({
        ...prevData,
        balance: response.data,
      }));
    }
  };

  const LoadTimerstart = async () => {
    //New Game Start//
    var response = await ApiService.Gametimerapi(Gametype, auth);
    const { status, message, data } = response.data;
    console.log(response);
    if (status) {
      if (data > 0 || data != null) {
        setgametime(data);
        setgameisActive(true);
        await LoadGameData();
      } else {
        await LoadTimerstart();
      }
    } else {
      await LoadTimerstart();
    }
    //New Game End//
  };

  const LoadGameData = async () => {
    //Game Data Bind Start//
    var responsegame = await ApiService.Gamedetails(Gametype, auth);
    console.log(responsegame);
    const { status, message, data } = responsegame.data;
    if (status) {
      currentgamedescription.current = data.gamename;
      setGameData((prevData) => ({
        ...prevData,
        gameid: data.id,
        currentgameid: data.gamename,
        previousgameid:
          prevData.previousgameid === ""
            ? data.gamename
            : prevData.currentgameid,
      }));

      console.log({ gameData });
    }

    //Game Data Bind End //
  };
  const LoadGameList = async () => {
    //Current Game Record Update//
    var responsecurrentrecord = await ApiService.CurrentGamelist(
      Gametype,
      openpage,
      auth
    );
    const { status, message, data } = responsecurrentrecord.data;
    settotalopenpage(data.totalPages);
    setHistory((prevdata) => ({
      ...prevdata,
      gamehistoryitem: data.item,
    }));
  };
  const LoadBetting = async () => {
    //Loading Bets//
    var responsecurrentrecord = await ApiService.BettingGamelist(
      Gametype,
      openbetspage,
      auth
    );

    const { status, message, data } = responsecurrentrecord.data;
    settotalopenbetspage(data.totalPages);
    setHistory((prevdata) => ({
      ...prevdata,
      mybetshistoryitem: data.item,
    }));
  };
  const currentbetsload = async () => {
    const response = await ApiService.CurrentBetslist(Gametype, auth);
    const { status, message, data } = response.data;

    if (status) {
      setHistory((prevdata) => ({
        ...prevdata,
        currentbetshistoryitem: data,
      }));
    } else {
      setHistory((prevdata) => ({
        ...prevdata,
        currentbetshistoryitem: [],
      }));
    }
  };
  const SubmitAllBets = async (e, bets, quantity, amount) => {
    const response = await ApiService.GamePlacebetting(
      {
        interval: Gametype,
        number: bets,
        amount: quantity * amount,
      },
      auth
    );
    const { status, message } = response.data;
    handleClose();
    if (status) {
      await LoadBalance();
      toast(message, { type: "success", isLoading: false });
      await currentbetsload();
    } else {
      toast(message, { type: "error", isLoading: false });
    }
  };

  useEffect(() => {
    if (firsttimerenderRef.current) {
      firsttimerenderRef.current = false;
      StartTimer(); // Call StartTimer only on the first render
    }
  }, []);

  useEffect(() => {
    return async () => {
      setgameisActive(false);
      await StartTimer();
    };
  }, [Gametype]);

  ////////////Counter Start////////////////
  useEffect(() => {
    let intervalId;

    if (isgameActive) {
      intervalId = setInterval(() => {
        if (gametime > 0) {
          setgametime((prevSeconds) => prevSeconds - 1);
        } else {
          setgameisActive(false);
          clearInterval(intervalId);
        }
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [isgameActive, gametime]);

  useEffect(() => {
    if (isgameActive) {
      if (gametime == 0) {
        //////console.log("restart game");
        stop();
        Showpopupwindow();
        StartTimer();
      } else if (gametime <= 5) {
        play();
        if (uiState.showModal) {
          handleClose();
        }
        //////console.log("bet Close");
        //Bet Close
      }
    }
  }, [gametime]);

  ////////////Counter End////////////////

  useEffect(() => {
    LoadGameList();
  }, [openpage]);

  useEffect(() => {
    LoadBetting();
  }, [openbetspage]);

  const btnrandom = async (e) => {
    e.preventDefault();

    for (let i = 0; i < 15; i++) {
      const number = 1 + Math.floor(Math.random() * 6);
      setGameData((prevData) => ({
        ...prevData,
        quantity: quanitytype(number),
        randomq: number,
      }));
      // Wait for 50ms
      await sleep(50);
    }
  };

  const btnbid = (e, quantity, random) => {
    e.preventDefault();
    setGameData((prevData) => ({
      ...prevData,
      quantity: quantity,
      randomq: random,
    }));
  };
  const btnallevents = (e, bettype, quantity) => {
    setGameData((prevGameData) => ({
      ...prevGameData,
      bettype: bettype,
    }));
    handleShow();
  };

  const handleShow = () => {
    setUiState((prevUiState) => ({
      ...prevUiState,
      showModal: true,
    }));
  };

  const handleClose = () => {
    setUiState((prevUiState) => ({
      ...prevUiState,
      showModal: false,
    }));
  };

  const ChangePageActive = (page) => {
    setGameData((prevGameData) => ({
      ...prevGameData,
      istabActive: page,
    }));
  };

  const displaynone = {
    display: "none",
  };
  const displayemnone = {};
  return (
    <>
      <LossModel
        show={lossgame}
        setShow={setlossgame}
        number={gameData.winnumber}
        color={gameData.color}
        shape={gameData.shape}
        wonamount={gameData.wonamount}
        wongamename={gameData.wongamename}
      />
      <WinModel
        show={wingame}
        setShow={setwingame}
        number={gameData.winnumber}
        color={gameData.color}
        shape={gameData.shape}
        wonamount={gameData.wonamount}
        wongamename={gameData.wongamename}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition: Slide
      />
      <section className="section-padding discover-nft-random margin-t-30">
        <div className="container">
          <div className="item-card-nft bg">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 mb-3">
                <div className="total">
                  <div className="d-flex align-items-center">
                    <img src={walleticon} width="40px" className="me-2" />
                    <h4>Total Balance</h4>
                  </div>
                  <div id="balancetotal" className="bal-amt">
                    ₹{gameData.balance}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-around">
                  <Link to="/deposit" className="btn new-btn">
                    Recharge
                  </Link>
                  <Link to="/withdraw" className="btn new-btn">
                    Withdrawal
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding margin-b-30">
        <div className="container">
          <div className="marq ">
            <i className="fas fa-bullhorn"></i>
            <marquee behavior="" direction="">
              Notice: To visit our official website, be sure to use the link
              below, https://domainname.com/ Please remember! Make sure not to
              provide personal data and personal transactions in any form and
              for any reason to other parties on behalf of GameName. Our side
              does not make private chats or calls to all members. Please inform
              all Referrals/other Members about this to avoid fraud. Thank you
              for your attention and cooperation.
            </marquee>
          </div>
        </div>
      </section>

      <section className="section-padding time-sec2">
        <div className="container">
          <div className="time-box">
            <ul>
              <li className={Gametype == 1 ? "active" : ""}>
                <Link
                  onClick={() => {
                    setgametype(1);
                  }}
                >
                  <img src={timer} alt="" />
                  <p>1 Min</p>
                </Link>
              </li>
              <li className={Gametype == 2 ? "active" : ""}>
                <Link
                  onClick={() => {
                    setgametype(2);
                  }}
                >
                  <img src={timer} alt="" />
                  <p>3 Min</p>
                </Link>
              </li>
              <li className={Gametype == 3 ? "active" : ""}>
                <Link
                  onClick={() => {
                    setgametype(3);
                  }}
                >
                  <img src={timer} alt="" />
                  <p>5 Min</p>
                </Link>
              </li>
              <li className={Gametype == 4 ? "active" : ""}>
                <Link
                  onClick={() => {
                    setgametype(4);
                  }}
                >
                  <img src={timer} alt="" />
                  <p>10 Min</p>
                </Link>
              </li>
              <li className={Gametype == 5 ? "active" : ""}>
                <Link
                  onClick={() => {
                    setgametype(5);
                  }}
                >
                  <img src={timer} alt="" />
                  <p>1 Hour</p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="tab-content ticket">
                <div className="u-id">
                  <div>
                    <span className="timing">30 sec</span>
                    <h3>{currentgamedescription.current}</h3>
                    {/* <h3>{status}</h3> */}
                  </div>
                  <div className="timer">
                    <h6 className="text-center text-white mb-2">
                      Time Remaining
                    </h6>
                    <div>
                      <span>{m1}</span>
                      <span>{m2}</span>
                      <span>:</span>
                      <span>{s1}</span>
                      <span>{s2}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding margin-t-30">
        <div className="container">
          <div className="game-sec">
            <div className="row">
              <div className="col-4">
                <button
                  onClick={(e) => {
                    btnallevents(e, 21, 1);
                  }}
                  className="btn btn-success"
                >
                  Green
                </button>
                <p className="prob">1.9</p>
              </div>
              <div className="col-4">
                <button
                  onClick={(e) => {
                    btnallevents(e, 22, 1);
                  }}
                  className="btn btn-warning voilet-btn"
                >
                  Violet
                </button>
                <p className="prob">4.8</p>
              </div>
              <div className="col-4">
                <button
                  onClick={(e) => {
                    btnallevents(e, 23, 1);
                  }}
                  className="btn btn-danger"
                >
                  Red
                </button>
                <p className="prob">1.9</p>
              </div>
            </div>

            <div className="balls">
              <ul>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 0, 1);
                    }}
                  >
                    <img src={chip1} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 1, 1);
                    }}
                  >
                    <img src={chip2} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 2, 1);
                    }}
                  >
                    <img src={chip3} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 3, 1);
                    }}
                  >
                    <img src={chip4} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 4, 1);
                    }}
                  >
                    <img src={chip5} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 5, 1);
                    }}
                  >
                    <img src={chip6} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 6, 1);
                    }}
                  >
                    <img src={chip7} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 7, 1);
                    }}
                  >
                    <img src={chip8} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 8, 1);
                    }}
                  >
                    <img src={chip9} />
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnallevents(e, 9, 1);
                    }}
                  >
                    <img src={chip10} />
                  </button>
                </li>
              </ul>
            </div>

            <div className="choose-bid">
              <ul id="selectbids">
                <li>
                  <button
                    onClick={(e) => {
                      btnrandom(e);
                    }}
                  >
                    Random
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnbid(e, 1, 1);
                    }}
                    className={gameData.randomq === 1 ? "active" : ""}
                  >
                    x1
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnbid(e, 5, 2);
                    }}
                    className={gameData.randomq === 2 ? "active" : ""}
                  >
                    x5
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnbid(e, 10, 3);
                    }}
                    className={gameData.randomq === 3 ? "active" : ""}
                  >
                    x10
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnbid(e, 20, 4);
                    }}
                    className={gameData.randomq === 4 ? "active" : ""}
                  >
                    x20
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnbid(e, 50, 5);
                    }}
                    className={gameData.randomq === 5 ? "active" : ""}
                  >
                    x50
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => {
                      btnbid(e, 100, 6);
                    }}
                    className={gameData.randomq === 6 ? "active" : ""}
                  >
                    x100
                  </button>
                </li>
              </ul>
            </div>

            <div className="bid-size">
              <button
                onClick={(e) => {
                  btnallevents(e, 24, 1);
                }}
                className="btn"
              >
                Big
              </button>
              <button
                onClick={(e) => {
                  btnallevents(e, 25, 1);
                }}
                className="btn"
              >
                Small
              </button>
            </div>
            <div
              className="counter"
              style={
                s1 == 0 && s2 <= 5 && s2 >= 1 ? displayemnone : displaynone
              }
            >
              <h1>
                <span>{minutes}</span>
                <span>{reSeconds}</span>
              </h1>
            </div>
            {/* <div
              className="counter"
              // style={uiState.showbox == 2 ? displayemnone : displaynone}
            >
              <h1 className="counter1">
                <span className="won">{gameData.winnumber}</span>
              </h1>
            </div> */}
          </div>
        </div>
      </section>

      <Gamealltable
        gameData={gameData}
        onchangepage={ChangePageActive}
        history={history}
        openpage={openpage}
        setopenpage={setopenpage}
        totalopenpage={totalopenpage}
        openbetspage={openbetspage}
        setopenbetspage={setopenbetspage}
        totalopenbetspage={totalopenbetspage}
      ></Gamealltable>

      <section className="margin-t-30  review-sec"></section>
      <section className="panel-space"></section>

      <ModalComponent
        show={uiState.showModal}
        handleClose={handleClose}
        valueamounts={gameData.amount}
        valuequantity={gameData.quantity}
        valuebettype={gameData.bettype}
        SubmitAllBets={SubmitAllBets}
      />
    </>
  );
}

export default Gamecolorpage;
