import React, { useEffect, useState } from "react";
import useAuth from "../context/useAuth";
import { Outlet } from "react-router-dom";
import Header from "../component/Header";
import { Link, useLocation, Navigate } from "react-router-dom";
import Bottomnavbar from "../component/Bottomnavbar";
import Headerback from "../component/Headerback";

function Dashboardlayout({ isback = true,titleheader="" }) {
  const [selectactive, setselectactive] = useState();
  const { auth, isAuthenticated } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.toString().toLowerCase() == "/home") {
      setselectactive(1);
    } else if (location.pathname.toString().toLowerCase() == "/activity") {
      setselectactive(2);
    } else if (location.pathname.toString().toLowerCase() == "/wallet") {
      setselectactive(3);
    } else if (location.pathname.toString().toLowerCase() == "/profile") {
      setselectactive(4);
    }
  }, [selectactive]);

  return (
    <div className="phone-width">
      {isback ? <Header /> : <Headerback title={titleheader} />}
      {isAuthenticated() && auth != null ? (
        <Outlet />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace></Navigate>
      )}
      {!isback ? (
        <></>
      ) : (
        <Bottomnavbar
          selectactive={selectactive}
          setselectactive={setselectactive}
        ></Bottomnavbar>
      )}
    </div>
  );
}

export default Dashboardlayout;
