import React, { useEffect, useState } from "react";
import { Modal, Button, ModalHeader } from "react-bootstrap";
import "./bottompopup.css";

const choosebetcolor = (type) => {
  switch (type) {
    case 0:
      return "Zero";
      break;
    case 1:
      return "One";
      break;
    case 2:
      return "Two";
      break;
    case 3:
      return "Three";
      break;
    case 4:
      return "Four";
      break;
    case 5:
      return "Five";
      break;
    case 6:
      return "Six";
      break;
    case 7:
      return "Seven";
      break;
    case 8:
      return "Eight";
      break;
    case 9:
      return "Nine";
      break;
    case 21:
      return "Green";
      break;
    case 22:
      return "Violet";
      break;
    case 23:
      return "Red";
      break;
    case 24:
      return "Big";
      break;
    case 25:
      return "Small";
      break;
    default:
      return "Choose Option";
      break;
  }
};

const ModalComponent = ({
  show,
  handleClose,
  valueamounts,
  valuequantity,
  valuebettype,
  SubmitAllBets,
}) => {
  //console.log(valueamounts);
  //console.log(valuequantity);
  const [amounts, setamounts] = useState(valueamounts);
  const [quantitys, setquantitys] = useState(valuequantity);
  const [bettypes, setbettypes] = useState(valuebettype);

  useEffect(() => {
    setamounts(valueamounts);
    setquantitys(valuequantity);
    setbettypes(valuebettype);
  }, [show]);

  const btnamountone = (e) => {
    e.preventDefault();
    setamounts(1);
  };
  const btnamountten = (e) => {
    e.preventDefault();
    setamounts(10);
  };
  const btnamounttwenty = (e) => {
    e.preventDefault();
    setamounts(20);
  };
  const btnamounthundred = (e) => {
    e.preventDefault();
    setamounts(100);
  };

  const btnquantity1 = (e) => {
    e.preventDefault();
    setquantitys(1);
  };
  const btnquantity2 = (e) => {
    e.preventDefault();
    setquantitys(5);
  };
  const btnquantity3 = (e) => {
    e.preventDefault();
    setquantitys(10);
  };
  const btnquantity4 = (e) => {
    e.preventDefault();
    setquantitys(20);
  };
  const btnquantity5 = (e) => {
    e.preventDefault();
    setquantitys(50);
  };
  const btnquantity6 = (e) => {
    e.preventDefault();
    setquantitys(100);
  };

  const btnmin = (e) => {
    e.preventDefault();
    var quan = quantitys > 2 ? parseInt( quantitys) - 1 : 1;
    setquantitys(quan);
  };

  const btnmax = (e) => {
    e.preventDefault();
    const quan = quantitys > 0 ? parseInt(quantitys) + 1 : 1;
    setquantitys(quan);
  };

  const Changequantity = (e) => {
    e.preventDefault();
    //if (e.target.value > 0) {
      setquantitys(e.target.value);
    //} else {
    //  setquantitys(0);
   // }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="color-bet bottom-modal transition-bottom"
    >
      <div className="modal-header">
        <h2 className="title-modal">Join Bet</h2>
        <h3 className="">Choose {choosebetcolor(bettypes)}</h3>
      </div>

      <Modal.Body>
        <div className="content-upload-item">
          <div className="d-flex justify-content-between align-items-center choose">
            <h6>Amount</h6>
            <ul className="nav nav-pills mb-3">
              <li className="nav-item">
                <button
                  onClick={btnamountone}
                  className={amounts === 1 ? "nav-link active" : "nav-link"}
                >
                  1
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={btnamountten}
                  className={amounts === 10 ? "nav-link active" : "nav-link"}
                >
                  10
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={btnamounttwenty}
                  className={amounts === 20 ? "nav-link active" : "nav-link"}
                >
                  20
                </button>
              </li>
              <li className="nav-item">
                <button
                  onClick={btnamounthundred}
                  className={amounts === 100 ? "nav-link active" : "nav-link"}
                >
                  100
                </button>
              </li>
            </ul>
          </div>

          <div className="d-flex justify-content-between align-items-center choose">
            <h6>Quantity</h6>
            <div className="quantity-field">
              <span onClick={btnmin}>-</span>
              <input
                type="number"
                value={quantitys}
                onChange={Changequantity}
              />
              <span onClick={btnmax}>+</span>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center choose">
            <ul id="selectbidsr" className="q-list">
              <li>
                <button
                  onClick={btnquantity1}
                  className={quantitys === 1 ? "active" : ""}
                >
                  x1
                </button>
              </li>
              <li>
                <button
                  onClick={btnquantity2}
                  className={quantitys === 5 ? "active" : ""}
                >
                  x5
                </button>
              </li>
              <li>
                <button
                  onClick={btnquantity3}
                  className={quantitys === 10 ? "active" : ""}
                >
                  x10
                </button>
              </li>
              <li>
                <button
                  onClick={btnquantity4}
                  className={quantitys === 20 ? "active" : ""}
                >
                  x20
                </button>
              </li>
              <li>
                <button
                  onClick={btnquantity5}
                  className={quantitys === 50 ? "active" : ""}
                >
                  x50
                </button>
              </li>
              <li>
                <button
                  onClick={btnquantity6}
                  className={quantitys === 100 ? "active" : ""}
                >
                  x100
                </button>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center rule">
            <img src="images/rule.png" alt="" width="40" className="me-3" />
            Pre-selling Rules
          </div>
          <div className=" bid-btns">
            <button onClick={handleClose}>Cancel</button>
            <button
              onClick={(e) => SubmitAllBets(e, bettypes, quantitys, amounts)}
            >
              Total : <span>₹{quantitys * amounts}</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
