import React,{ useEffect, useState } from 'react'
import personimage from "../../images/person/p3.png";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";

const Account = ({titles}) => {

    titles('Account');
    const { auth } = useAuth();

    const [memberid,setmemberid]=useState('');
    const [phoneno,setphoneno]=useState('');
    const [email,setemail]=useState('');
    const [name,setname]=useState('');
    const [sponsor,setsponsor]=useState('');


    useEffect(() => {
        profileaccount();
        return () => {profileaccount();};
      }, []);
   

    const profileaccount = async () => {
        var response = await ApiService.getprofile(auth);
        const { status, message, data } = response.data;
        
        if(status)
        {
            setmemberid(data.memberid);
            setphoneno(data.phoneno);
            setemail(data.email);
            setname(data.name);
            setsponsor(data.sponsor);
        }
      };

    return (
        <div>
            <section className='acc-sec'>
                <div className="custom-container">
                    <div className="row">
                        <div className="profile-banner">
                            <div className="profile-image">
                                <img
                                    className="img-fluid profile-pic"
                                    src={personimage}
                                    alt="p3"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className='acc-box'>
                <div className="custom-container">
                    <div className="acc-details">
                        <ul>
                            <li>Member ID <span>{memberid}</span></li>
                            <li>Phone No <span>{phoneno}</span></li>
                            <li>Email ID <span>{email}</span></li>
                            <li>Full Name <span>{name}</span></li>
                            <li>Sponser ID <span>{sponsor}</span></li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Account
