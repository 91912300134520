import React from "react";
import Gamehistory from "./Gamehistory";
import CurrentBetting from "./CurrentBetting";
import MyBettinghistory from "./MyBettinghistory";

function Gamealltable(props) {
  return (
    <section className="section-padding margin-t-15  review-sec">
      <div className="last-sect">
        <ul className="nav nav-pills mb-3 section-padding justify-content-center">
          <li className="nav-item" role="presentation">
            <button
              className={
                props.gameData.istabActive === 1
                  ? "nav-link active"
                  : "nav-link"
              }
              onClick={(e) => {
                e.preventDefault();
                props.onchangepage(1);
              }}
              type="button"
            >
              Game History
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className={
                props.gameData.istabActive === 2 ? "nav-link active" : "nav-link"
              }
              type="button"
              onClick={(e) => {
                e.preventDefault();
                props.onchangepage(2);
              }}
            >
              My Bets
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={
                props.gameData.istabActive === 3 ? "nav-link active" : "nav-link"
              }
              onClick={(e) => {
                e.preventDefault();
                props.onchangepage(3);
              }}
              type="button"
            >
              Trend
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={
                props.gameData.istabActive === 4 ? "nav-link active" : "nav-link"
              }
              onClick={(e) => {
                e.preventDefault();
                props.onchangepage(4);
              }}
              type="button"
            >
              Current Bets
            </button>
          </li>
        </ul>
      </div>

      <div className="tab-content">
        <div
          className={
            props.gameData.istabActive === 1
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
        >
          <Gamehistory
            items={props.history.gamehistoryitem}
            openpage={props.openpage}
            setopenpage={props.setopenpage}
            totalopenpage={props.totalopenpage}
          ></Gamehistory>
        </div>
        <div
          className={
            props.gameData.istabActive === 2
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
        >
           <MyBettinghistory
            items={props.history.mybetshistoryitem}
            openbetspage={props.openbetspage}
            setopenbetspage={props.setopenbetspage}
            totalopenbetspage={props.totalopenbetspage}
          ></MyBettinghistory> 
        </div>
        <div
          className={
            props.gameData.istabActive === 3
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
        >
          <table className="table-responsive table2">
            <thead>
              <tr>
                <th>Period</th>
                <th>Statistics (Last 100 Period)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Total Number</th>
                <td>
                  <ul className="stat-point">
                    <li>0</li>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Winning</th>
                <td>
                  <ul className="stat-point">
                    <li>0</li>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Average Missing</th>
                <td>
                  <ul className="stat-point">
                    <li>0</li>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Frequency</th>
                <td>
                  <ul className="stat-point">
                    <li>0</li>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Max Consecutive</th>
                <td>
                  <ul className="stat-point">
                    <li>0</li>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={
            props.gameData.istabActive === 4
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
        >
          <CurrentBetting
            items={props.history.currentbetshistoryitem}
          ></CurrentBetting>
        </div>
      </div>
    </section>
  );
}

export default Gamealltable;
