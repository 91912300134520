import React from 'react'
import Withdrawhistorytab from '../../component/Withdrawhistorytab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

const Withdrawupi = ({titles}) => {
    titles('Withdrawal History');
    return (
        <div>
            <section className='wh-sec'>
                <div className="custom-container">
                    <Withdrawhistorytab />
                    <div className="row mt-3">
                        {/* <div className="col-12">
                            <div className="transact-details">
                                <div className="top">
                                    <span className="heading">Withdraw</span>
                                    <span className="status">Completed</span>
                                </div>
                                <ul>
                                    <li>Balance<span>₹5000</span></li>
                                    <li>Type<span>Bank Card</span></li>
                                    <li>Time<span>2024-06-01 21:15:35</span></li>
                                    <li>Order Number<span>WD2024060107093592606145d <FontAwesomeIcon icon={faCopy} /></span></li>
                                    <li>Remarks<span></span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="transact-details">
                                <div className="top">
                                    <span className="heading">Withdraw</span>
                                    <span className="status red">Rejected</span>
                                </div>
                                <ul>
                                    <li>Balance<span>₹5000</span></li>
                                    <li>Type<span>Bank Card</span></li>
                                    <li>Time<span>2024-06-01 21:15:35</span></li>
                                    <li>Order Number<span>WD2024060107093592606145d <FontAwesomeIcon icon={faCopy} /></span></li>
                                </ul>
                                <div className="bottom">
                                    <h6>Remarks</h6>
                                    <p>Due to your account security, Please contact with Customer Service to verify. Thank you!</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Withdrawupi
