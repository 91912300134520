import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import tether from "../../images/tether.png";
import upi from "../../images/upi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faCreditCardAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";
import upicode from "../../images/upicode.png";

const Addmoney = ({ titles }) => {
  titles("Add Money");
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { amount: null, paymethod: 1 } });
  const navigate = useNavigate();

  const [userdeposit, setuserdeposit] = useState({
    balance: 0,
    balanceunlisted: 0,
    winnings: 0,
  });

  const moneysymbols = (method) => {
    switch (method) {
      case 1:
        return "$";
        break;
      case 2:
        return "₹";
        break;
      default:
        return "$";
        break;
    }
  };

  const HandledepositClick = async (data) => {
    if(getValues("paymethod") == 1)
    {
      if (getValues("amount") >= 10) {
        navigate("/depositcoin/" + getValues("amount"));
      } else {
        setValue("amount", 10);
      }
    }
    else{
      if (getValues("amount") >= 100) {
        navigate("/depositmoney/" + getValues("amount"));
      } else {
        setValue("amount", 100);
      }
    }
  };

  const updatebalance = async () => {
    const response = await ApiService.getbalance(auth);
    const { status, message, data } = response.data;
    if (status) {
      setuserdeposit((prevState) => ({ ...prevState, balance: data }));
    }
  };


  // const inramount = [100, 200, 300, 400, 500, 1000];
  // const dollaramount = [10, 20, 30, 40, 50, 100];

  const updatePaymethod = (newPaymethod) => {
    setValue("paymethod", newPaymethod);
  };
  const updateAmount = (newAmount) => {
    setValue("amount", newAmount);
  };
  const handlepaymentmethod = async (values) => {
    updatePaymethod(values);
  };

  useEffect(() => {
    updatebalance();
    return () => {updatebalance();};
  }, []);

  return (
    <div>
      <section className="add-money">
        <div className="custom-container">
          <ul className="">
            <li>
              <span>
                <FontAwesomeIcon icon={faWallet} className="iconsize" /> Current
                Balance
              </span>{" "}
              <span>₹{userdeposit.balance}</span>
            </li>
          </ul>
          <hr className="my-0 text-white" />
        </div>
      </section>
      <section className="pt-0 add-money-2">
        <div className="custom-container">
          <div className="title">
            <h6 className="white-text">
              <FontAwesomeIcon icon={faCreditCardAlt} className="iconsize" />{" "}
              Payment Method
            </h6>
          </div>
          <form onSubmit={handleSubmit(HandledepositClick)}>
            <div className="row">

            <div className="col-6 text-center">
                <Link
                  onClick={() => {
                    handlepaymentmethod(1);
                  }}
                  className={
                    getValues("paymethod") === 1 ? "pmt-box active" : "pmt-box"
                  }
                >
                  <img src={tether} alt="icon" />
                  <h6>USDT(BEP20)</h6>
                </Link>
              </div>

              {/* <div className="col-6 text-center">
                <Link
                  onClick={() => {
                    handlepaymentmethod(2);
                  }}
                  className={
                    getValues("paymethod") === 2 ? "pmt-box active" : "pmt-box"
                  }
                >
                  <img src={upi} alt="icon" />
                  <h6>GIG ($)</h6>
                </Link>
              </div> */}

              
            </div>

            {/* <div className="row">
              <div className="col-4">
                <div
                  onClick={() => {
                    updateAmount(
                      getValues("paymethod") == 1
                        ? inramount[0]
                        : dollaramount[0]
                    );
                  }}
                  className="amt"
                >
                  {moneysymbols(getValues("paymethod"))}{" "}
                  {getValues("paymethod") == 1 ? inramount[0] : dollaramount[0]}
                </div>
              </div>
              <div className="col-4">
                <div
                  onClick={() => {
                    updateAmount(
                      getValues("paymethod") == 1
                        ? inramount[1]
                        : dollaramount[1]
                    );
                  }}
                  className="amt"
                >
                  {moneysymbols(getValues("paymethod"))}{" "}
                  {getValues("paymethod") == 1 ? inramount[1] : dollaramount[1]}
                </div>
              </div>
              <div className="col-4">
                <div
                  onClick={() => {
                    updateAmount(
                      getValues("paymethod") == 1
                        ? inramount[2]
                        : dollaramount[2]
                    );
                  }}
                  className="amt"
                >
                  {moneysymbols(getValues("paymethod"))}{" "}
                  {getValues("paymethod") == 1 ? inramount[2] : dollaramount[2]}
                </div>
              </div>
              <div className="col-4">
                <div
                  onClick={() => {
                    updateAmount(
                      getValues("paymethod") == 1
                        ? inramount[3]
                        : dollaramount[3]
                    );
                  }}
                  className="amt"
                >
                  {moneysymbols(getValues("paymethod"))}{" "}
                  {getValues("paymethod") == 1 ? inramount[3] : dollaramount[3]}
                </div>
              </div>
              <div className="col-4">
                <div
                  onClick={() => {
                    updateAmount(
                      getValues("paymethod") == 1
                        ? inramount[4]
                        : dollaramount[4]
                    );
                  }}
                  className="amt"
                >
                  {moneysymbols(getValues("paymethod"))}{" "}
                  {getValues("paymethod") == 1 ? inramount[4] : dollaramount[4]}
                </div>
              </div>
              <div className="col-4">
                <div
                  onClick={() => {
                    updateAmount(
                      getValues("paymethod") == 1
                        ? inramount[5]
                        : dollaramount[5]
                    );
                  }}
                  className="amt"
                >
                  {moneysymbols(getValues("paymethod"))}{" "}
                  {getValues("paymethod") == 1 ? inramount[5] : dollaramount[5]}
                </div>
              </div>
            </div> */}

            <div className="deposit-details">
              <div className="title">
                <h6 className="white-text mb-0">
                  {getValues("paymethod") === 1 ? (
                     <>
                     <img src={tether} alt="tether" width="30px" /> In
                     USDT(BEP20){" "}
                   </>
                   
                  ) : (
                    <>
                      <img src={upi} alt="ruppee" width="30px" /> In Ruppes(₹)
                    </>
                  )}
                </h6>
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  {moneysymbols(getValues("paymethod"))}
                </span>

                <input
                  type="number"
                  {...register("amount", { required: true })}
                  className={`form-control ${
                    errors.amount ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Deposit Amount"
                  autoComplete="off"
                />
                {errors.amount && (
                  <div className="invalid-feedback">
                    Deposit Amount is required
                  </div>
                )}
              </div>

              <button type="submit" className="pmt-btn mb-2">
                Deposit Funds
              </button>
              <ul className="notes">
                <li>
                  * If you transfer the wrong amount as instructed, the funds
                  will be lost, and our company will not be held responsible!
                </li>
                <li>
                  * You must deposit the exact amount as instructed in the
                  order. The system will automatically update the bonus funds.
                </li>
              </ul>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Addmoney;
