import React, { Component, useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  BrowserRouter,
} from "react-router-dom";

import "./App.css";
import "./Main.css";

import Mainlayout from "./layout/Mainlayout";
import Dashboardlayout from "./layout/Dashboardlayout";

import Loginpage from "./pages/Loginpage";
import Signuppage from "./pages/Signuppage";
import Forgotpage from "./pages/Forgotpage";
import Logoutpage from "./pages/Logoutpage";

import Gamecolorpage from "./pages/lotterygame/Gamecolorpage";
import { AuthProvider } from "./context/AuthProvider";

import Homepage from "./pages/dashbordpage/Homepage";
import Activitypage from "./pages/dashbordpage/Activitypage";
import Profilepage from "./pages/dashbordpage/Profilepage";
import Walletpage from "./pages/dashbordpage/Walletpage";
import Promotion from "./pages/dashbordpage/Promotion";

import Account from "./pages/profiledashpage/Account";
import Settingpage from "./pages/profiledashpage/Settingpage";
import Helppage from "./pages/profiledashpage/Helppage";

import Addmoney from "./pages/backdashboardpage/Addmoney";
import Addmoneybep20 from "./pages/backdashboardpage/Addmoneybep20";
import Addmoneysecond from "./pages/backdashboardpage/Addmoneysecond";
import BankDetails from "./pages/backdashboardpage/BankDetails";
import Deposithistory from "./pages/backdashboardpage/Deposithistory";
import Transfer from "./pages/backdashboardpage/Transfer";
import Withdrawal from "./pages/backdashboardpage/Withdrawal";
import Withdrawaladdress from "./pages/backdashboardpage/Withdrawaladdress";
import Withdrawhistory from "./pages/backdashboardpage/Withdrawhistory";
import Withdrawtrc from "./pages/backdashboardpage/Withdrawtrc";
import Withdrawupi from "./pages/backdashboardpage/Withdrawupi";

function App() {
  const [title, settile] = useState("");

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: "<h1>Error Page</h1>",
      element: <Mainlayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/login" />,
        },
        { path: "/login", element: <Loginpage /> },
        { path: "/signup", element: <Signuppage /> },
        { path: "/forgot", element: <Forgotpage /> },
      ],
    },

    {
      path: "/",
      errorElement: "<h1>Error Page</h1>",
      element: <Dashboardlayout isback={true} titleheader="Game" />,
      children: [
        { path: "/home", element: <Homepage /> },
        { path: "/game", element: <Gamecolorpage /> },
        { path: "/activity", element: <Activitypage /> },
        { path: "/profile", element: <Profilepage /> },
        { path: "/wallet", element: <Walletpage /> },
        { path: "/promote", element: <Promotion /> },
        { path: "/logout", element: <Logoutpage /> },
      ],
    },
    {
      path: "/",
      errorElement: "<h1>Error Page</h1>",
      element: <Dashboardlayout isback={false} titleheader={title} />,
      children: [
        { path: "/account", element: <Account titles={settile} /> }, //onclick={settile("Setting")}
        { path: "/setting", element: <Settingpage titles={settile} /> }, //onclick={settile("Setting")}
        { path: "/help", element: <Helppage titles={settile} /> },

        { path: "/deposit", element: <Addmoney titles={settile} /> },
        {
          path: "/depositmoney/:type",
          element: <Addmoneysecond titles={settile} />,
        },
        {
          path: "/depositcoin/:type",
          element: <Addmoneybep20 titles={settile} />,
        },
        { path: "/withdraw", element: <Withdrawal titles={settile} /> },
        { path: "/address", element: <Withdrawaladdress titles={settile} /> },
        { path: "/bankdetails", element: <BankDetails titles={settile} /> },
        { path: "/transfer", element: <Transfer titles={settile} /> },
        {
          path: "/deposithistory",
          element: <Deposithistory titles={settile} />,
        },
        {
          path: "/withdrawhistory",
          element: <Withdrawhistory titles={settile} />,
        },
        { path: "/withdrawupi", element: <Withdrawupi titles={settile} /> },
        { path: "/withdrawtrx", element: <Withdrawtrc titles={settile} /> },
      ],
    },
  ]);

  return (
    <div>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
}

export default App;
