import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight,faAngleLeft } from "@fortawesome/free-solid-svg-icons";

function Gamehistory({ items,openpage,setopenpage ,totalopenpage}) {
  const ColorChart = (number) => {
    const type = parseInt(number);
    switch (type) {
      case 0:
        return "redviolet";
        break;
      case 1:
        return "green";
        break;
      case 2:
        return "red";
        break;
      case 3:
        return "green";
        break;
      case 4:
        return "red";
        break;
      case 5:
        return "greenviolet";
        break;
      case 6:
        return "red";
        break;
      case 7:
        return "green";
        break;
      case 8:
        return "red";
        break;
      case 9:
        return "green";
        break;
      default:
        return "Choose Option";
        break;
    }
  };
  const colorshow = (number) => {
    if (
      parseInt(number) === 2 ||
      parseInt(number) === 4 ||
      parseInt(number) === 6 ||
      parseInt(number) === 8
    ) {
      return <div className="colorRed"></div>;
    } else if (
      parseInt(number) === 1 ||
      parseInt(number) === 3 ||
      parseInt(number) === 7 ||
      parseInt(number) === 9
    ) {
      return <div className="colorGreen"></div>;
    } else if (parseInt(number) === 0) {
      return (
        <>
          <div className="colorRed"></div>
          <div className="colorYellow"></div>
        </>
      );
    } else if (parseInt(number) === 5) {
      return (
        <>
          <div className="colorGreen"></div>
          <div className="colorYellow"></div>
        </>
      );
    } else {
      <></>;
    }
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Draw</th>
            <th>Number</th>
            <th>Big/Small</th>
            <th>Colour</th>
          </tr>
        </thead>
        <tbody>
          {items!= null && items.length > 0 ? (
            items.map((item, index) => (
              <tr key={index}>
                <td>{item.gamename}</td>
                <td className={"lotterygamenumber_" + ColorChart(item.number)}>
                  {item.number}
                </td>
                <td>{item.shape}</td>
                <td>
                  {colorshow(item.number)}

                  {/* <div className={"color-" + item.color}></div>
                  <div className={"color-" + item.color}></div> */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <p>No Record Found</p>
              </td>
            </tr>
          )}
        </tbody>

        
      </table>

      <nav aria-label="Page navigation example ">
        <ul className="pagination justify-content-center gap-3 mt-3">
          <li className="page-item">
            <Link className="page-link arrow" onClick={()=>{if(openpage>1)setopenpage(openpage-1)}}>
            <FontAwesomeIcon  icon={faAngleLeft} className="arrow-icon" />
            </Link>
          </li>
          <li className="page-item">
              <span className="no">{openpage}</span>/ <span className="total">{totalopenpage}</span>
          </li>
        
          <li className="page-item">
            <Link className="page-link arrow" onClick={()=>{setopenpage(openpage+1)}}>
            <FontAwesomeIcon  icon={faAngleRight} className="arrow-icon"/>
            </Link>
          </li>
        </ul>
      </nav>
     
    </>
  );
}

export default Gamehistory;
