import React,{useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import tether from "../../images/tether.png";
import upi from "../../images/upi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCardAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";

function Withdrawaladdress({ titles }) {
  titles("Withdrawal Address");
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { address: "" } });
  const navigate = useNavigate();

  useEffect(() => {
    
    return () => {profileaccount();};
  }, []);

  const profileaccount = async () => {
    var response = await ApiService.getcryptoaddress(auth);
    const { status, message, data } = response.data;
    if (status) {
      setValue("address", data.usdt);
    }
  };
  const UpdateAddressCrypto = async (cryptodata) => {
    var response = await ApiService.updatecryptoaddress(
      {
        address: cryptodata.address,
      },
      auth
    );
    const { status, message, errors, data } = response.data;

    if (status) {
      toast(message, { type: "success", isLoading: false });
    } else {
      if (errors.length > 0) {
        errors.forEach((element) => {
          toast(element, { type: "error", isLoading: false });
        });
      } else {
        toast(message, { type: "error", isLoading: false });
      }
    }
  };

  const Submitwithdrawal = async (data) => {
    await UpdateAddressCrypto(data);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition: Slide
      />
      <section className="">
        <div className="custom-container">
          <form onSubmit={handleSubmit(Submitwithdrawal)}>
            <div className="title">
              <h6 className="white-text">
                <FontAwesomeIcon icon={faCreditCardAlt} className="iconsize" />{" "}
                Update Your USDT(BEP-20) Address
              </h6>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("address", { required: true })}
                    className={`form-control ${
                      errors.address ? "is-invalid" : ""
                    }`}
                    placeholder="Enter USDT Address"
                    autoComplete="off"
                  />
                  {errors.address && (
                    <div className="invalid-feedback">
                      USDT Address is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mt-3">
                <button type="submit" className="pmt-btn mb-2">
                  Update Address
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Withdrawaladdress;
