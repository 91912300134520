import React, { useEffect, useState } from "react";
import Withdrawhistorytab from "../../component/Withdrawhistorytab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";

const Withdrawhistory = ({ titles }) => {
  titles("Withdrawal History");
  const { auth } = useAuth();
  const [activetab, setactivetab] = useState(1);
  const [history, sethistory] = useState([]);
  const [optiontab, setoptiontab] = useState();
  const [datedtab, setdatedtab] = useState();

  const Withdrawalhistory = async () => {
    const response = await ApiService.getwithdrawalhistory({}, auth);
    const { status, message, data } = response.data;
    if (status) {
      sethistory(data);
    }
  };

  useEffect(() => {
    //console.log("click");


    return async() => {
      //console.log('click1');
      //await Withdrawalhistory();
    };
  }, [activetab, datedtab]);

  return (
    <div>
      <section className="wh-sec">
        <div className="custom-container">
          <Withdrawhistorytab
            activetab={activetab}
            setactivetab={setactivetab}
            dated={datedtab}
            setdated={setdatedtab}
          />

          <div className="row mt-3">
            {/* {history.length > 0 ? (
              history.map((item, index) => (
                <div className="col-12">
                  <div className="transact-details">
                    <div className="top">
                      <span className="heading">Withdraw</span>
                      <span className="status">{item.status}</span>
                    </div>
                    <ul>
                      <li>
                        Balance<span>₹{item.balance}</span>
                      </li>
                      <li>
                        Type<span>{item.type}</span>
                      </li>
                      <li>
                        Time<span>{item.dated}</span>
                      </li>
                      <li>
                        Order Number
                        <span>
                          {item.orderid}
                          <FontAwesomeIcon icon={faCopy} />
                        </span>
                      </li>
                      <li>
                        Remarks<span>{item.remark}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12">
                <p className="text-white">No Record Found</p>
              </div>
            )} */}

            <div className="col-12">
                        <div className="transact-details">
                            <div className="top">
                                <span className="heading">Withdraw</span>
                                <span className="status">Completed</span>
                            </div>
                            <ul>
                                <li>Balance<span>₹5000</span></li>
                                <li>Type<span>Bank Card</span></li>
                                <li>Time<span>2024-06-01 21:15:35</span></li>
                                <li>Order Number<span>WD2024060107093592606145d <FontAwesomeIcon icon={faCopy}/></span></li>
                                <li>Remarks<span></span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="transact-details">
                            <div className="top">
                                <span className="heading">Withdraw</span>
                                <span className="status red">Rejected</span>
                            </div>
                            <ul>
                                <li>Balance<span>₹5000</span></li>
                                <li>Type<span>Bank Card</span></li>
                                <li>Time<span>2024-06-01 21:15:35</span></li>
                                <li>Order Number<span>WD2024060107093592606145d <FontAwesomeIcon icon={faCopy}/></span></li>
                            </ul>
                            <div className="bottom">
                            <h6>Remarks</h6>
                            <p>Due to your account security, Please contact with Customer Service to verify. Thank you!</p>
                            </div>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Withdrawhistory;
