import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight,faAngleLeft } from "@fortawesome/free-solid-svg-icons";

function MyBettinghistory({ items,openbetspage,setopenbetspage ,totalopenbetspage }) {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Draw</th>
            <th>Bet</th>
            <th>Amount</th>
            <th>Winamount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          { items.length > 0 ? (
            items.map((item, index) => (
              <tr key={index}>
                <td>{item.gamename}</td>
                <td>{item.gametype}</td>
                <td>₹{item.amount}</td>
                <td>₹{item.winamount}</td>
                <td>{item.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <p>No Record Found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <nav aria-label="Page navigation example ">
        <ul className="pagination justify-content-center gap-3 mt-3">
          <li className="page-item">
            <Link className="page-link arrow" onClick={()=>{if(openbetspage>1)setopenbetspage(openbetspage-1)}}>
            <FontAwesomeIcon  icon={faAngleLeft} className="arrow-icon" />
            </Link>
          </li>
          <li className="page-item">
              <span className="no">{openbetspage}</span>/ <span className="total">{totalopenbetspage}</span>
          </li>
        
          <li className="page-item">
            <Link className="page-link arrow" onClick={()=>{setopenbetspage(openbetspage+1)}}>
            <FontAwesomeIcon  icon={faAngleRight} className="arrow-icon"/>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default MyBettinghistory;
