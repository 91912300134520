import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import ApiService from "../Api/ApiService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "../context/useAuth";

function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const { login, isAuthenticated } = useAuth();

  const onSubmit = async (data) => {
    const tid = toast.loading("Please wait...", { isLoading: true });

    await login(
      data,
      (successmessage) => {
        toast.update(tid, {
          render: successmessage,
          type: "success",
          hideProgressBar: false,
          isLoading: false,
          autoClose: 2000,
        });

        setTimeout(() => {
          navigate("/home");
        }, 3000);
      },
      (errormessage) => {
        toast.update(tid, {
          render: errormessage,
          type: "error",
          hideProgressBar: false,
          isLoading: false,
          autoClose: 2000,
        });
      }
    );
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="auth-form">
        <div className="custom-container">
          <div className="text-center">
            <img src="/logo.png" alt="" className="logo" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="form-main">
            <div className="form-group">
              <label htmlFor="username" className="form-label">
                Email ID
              </label>
              <input
                type="text"
                id="username"
                {...register("username", { required: true })}
                className={`form-control ${errors.username ? "is-invalid" : ""}`}
                placeholder="Enter Email ID"
                autoComplete="off"
              />
              {errors.username && (
                <div className="invalid-feedback">Email ID is required</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                {...register("password", { required: true })}
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                }`}
                placeholder="******"
                autoComplete="off"
              />
              {errors.password && (
                <div className="invalid-feedback">Password is required</div>
              )}
            </div>

            <div className="remember-option mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  {...register("rememberme")}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Remember Me
                </label>
              </div>
              <Link className="forgot" to="/forgot">
                Forgot Pin?
              </Link>
            </div>

            <Button type="submit" className="cmn-btn w-100">
              Sign In
            </Button>

            <p className="signup">
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
          </form>
        </div>
      </div>

      <div className="space-sticky"></div>
      <div className="border-sections-home"></div>
    </>
  );
}

export default LoginPage;
