import React  from "react";

import Modal from "react-bootstrap/Modal";
import Loss from "../../../images/loss.png";
import "./lossmodel.css";

const LossModel = ({ show, setShow,number,color,shape,wonamount ,wongamename}) => {
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  return (
    <>
      <Modal show={show} onHide={handleClose} className="winmod losmod">
        <Modal.Header closeButton>
          <img src={Loss} alt="" className="headlabel" />
        </Modal.Header>
        <Modal.Body>
          <h2>Sorry!</h2>
          <div className="lottery">
            <h6>Game Result</h6>
            <ul>
              <li>{color}</li>
              <li>{number}</li>
              <li>{shape}</li>
            </ul>
          </div>
          <div className="bonus">
            <span>Better Luck Next Time</span>
            <h3>LOSE</h3>
          </div>
          <p>Game: {wongamename}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LossModel;
