import { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import ApiService from "../Api/ApiService";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);

  const [loading, setLoading] = useState(true);

  const login = async (data, success, failed) => {
    try {
      const response = await ApiService.logincallapi(data);
      const { status, message, data: responsedata } = response.data;
      if (status) {
        setAuth(responsedata.token);
        localStorage.setItem("authToken", responsedata.token);
        success(message);
      } else {
        logout();
        failed(message);
      }
    } catch (err) {
      logout();
      failed(err.message);
    }
  };

  const logout = () => {
    setAuth(null);
    localStorage.removeItem("authToken")
  };

  const isAuthenticated = () => {
    return auth != null ? true : false; 
  };
 

  useEffect(() => {
    const fetchvaliduser = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await ApiService.validatelogintoken({ token: token });
        const { status, message } = response.data;
        if (status) {
          setAuth(token);
          localStorage.setItem("authToken", token);
        } else {
          logout();
        }
      } catch (err) {
        logout();
      }
      setLoading(false);
    };
   
    fetchvaliduser();
  }, []);

  return (
    <AuthContext.Provider
      value={{ auth, login, logout, isAuthenticated }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
