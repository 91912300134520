import React, { useState, useEffect } from 'react';
import './ImageSlider.css';
import { Link } from "react-router-dom";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <>
    <div className="swiper banner">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <Link>
                    <img loading='lazy' src={images[currentIndex]} alt="slide" className="img-fluid banner-img" />
                </Link>
            </div>
        </div>

</div>


    {/* <div className="slider">
      { <button onClick={prevSlide} className="slider-button">Previous</button> 
      
      { <button onClick={nextSlide} className="slider-button">Next</button> 
    </div> */}
    </>
  );
};

export default ImageSlider;

