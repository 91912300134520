import React from 'react'
import { Outlet } from "react-router-dom";
import "./Mainlayout.css";
import useAuth from "../context/useAuth";
import { Link, useLocation, Navigate } from "react-router-dom";

function Mainlayout() {
  const { auth, isAuthenticated } = useAuth();
  const location = useLocation();

  return (
    <>
    <div className="auth-body">
      <div className="phone-width">
      
      {!isAuthenticated() ? (
        <Outlet />
       ) : (
        <Navigate to="/home" state={{ from: location }} replace></Navigate>
      )} 
      </div>
    </div>
    </>
  )
}

export default Mainlayout