import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

function Headerback({title}) {
  const navigate = useNavigate();
  return (
    <header className="section-t-space">
      <div className="custom-container">
        <div className="header-panel">
          <Link onClick={() => navigate(-1)} className="back-btn">
            <FontAwesomeIcon icon={faArrowLeft} className="icon" />
          </Link>
          <h2 className="text-white">{title}</h2>
          <Link onClick={() => navigate(0)} className="back-btn">
            <FontAwesomeIcon icon={faRotateRight} className="icon" />
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Headerback;
