import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import tether from "../../images/tether.png";
import upi from "../../images/upi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCardAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import upicode from "../../images/upicode.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";

function Addmoneysecond({ titles }) {
  titles("Add Money");
  const { type } = useParams();
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { amount: type } });
  const navigate = useNavigate();

  const [upiaddress, setupiaddress] = useState("");
  const [upiimage, setupiimage] = useState("");

  const Submitwithdrawal = async (data) => {
    //console.log({ data });
    await DepositPlace(data);
    //Api Code
    //navigate("/home");
  };

  const DepositPlace = async (dataupi) => {
    const response = await ApiService.depositplace(
      {mode:1, amount: dataupi.amount, utrnumber: dataupi.utrnumber },
      auth
    );
    const { status, message, data } = response.data;

    if (status) {
      toast(message, { type: "success", isLoading: false });
      navigate("/deposithistory");
    } else {
      toast(message, { type: "error", isLoading: false });
    }
  };
  const ShowUpi = async () => {
    var response = await ApiService.getupiId({}, auth, type, 1);
    console.log(response);
    const { status, message, data } = response.data;
    if (status) {
      //console.log({ data });
      const { imageurl, upi } = data;
      setupiimage(imageurl);
      setupiaddress(upi);
    } else {
    }
  };

  useEffect(() => {
    ShowUpi();
  }, []);

  const CopyReferID = () => {
    toast("UPI ID is Copied.", { type: "success", isLoading: false });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition: Slide
      />
      <section className="refer scanpay">
        <div className="custom-container">
          <form onSubmit={handleSubmit(Submitwithdrawal)}>
            <div className="title">
              <h6 className="white-text">
                <FontAwesomeIcon icon={faCreditCardAlt} className="iconsize" />{" "}
                Scan Upi ID to Pay
              </h6>
            </div>

            <div className="row mb-3">
              <div className="col-12 text-center">
                <img src={upiimage} alt="Upi" style={{ width: "50%" }} />
              </div>

              <div className="col-12 mt-2">
                <div className="ref-box">
                  <CopyToClipboard text={upiaddress} onCopy={CopyReferID}>
                    <div className="copy-area">
                      <Link className="share-btn">
                        {upiaddress} <FontAwesomeIcon icon={faCopy} />
                      </Link>
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center mb-3">
                <div className="payAmount">
                  <span>Pay Amount : </span>
                  <h5 className="mb-0"> ₹{getValues("amount")}</h5>
                </div>
              </div>

              <div className="col-12  ">
                <label htmlFor="" className="text-white mb-1">
                  Enter URT Number
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    {...register("utrnumber", { required: true })}
                    className={`form-control ${
                      errors.utrnumber ? "is-invalid" : ""
                    }`}
                    placeholder="Enter 12 DIGIT UTR Number"
                    autoComplete="off"
                  />
                  {errors.utrnumber && (
                    <div className="invalid-feedback">
                      UTR Number is required
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <button type="submit" className="pmt-btn mb-2">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Addmoneysecond;
